export const SLACK_ENDPOINT = 'https://hooks.slack.com/services/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'

export const SLACK_PAYLOAD = values => {
    return {
        'text': 'New message',
        'blocks': [
            {
                'type': 'header',
                'text': {
                    'type': 'plain_text',
                    'text': 'Website Enquiry'
                }
            },
            {
                'type': 'section',
                'text': {
                    'type': 'mrkdwn',
                    'text': 'Details'

                }
            },
            {
                'type': 'section',
                'fields':[
                    {
                        'type': 'mrkdwn',
                        'text': `•*Name*: ${values.name} \n\n•*Email*: ${values.email}`
                    }
                ]
            },
            {
                'type': 'divider'
            },
            {
                'type': 'section',
                'text': {
                    'type': 'mrkdwn',
                    'text': `*Message*\n\n${values.message}`

                }
            }
        ]

    }
}

export const SEGMENT_IDENTIFY = values => {
    return window.analytics.identify({
        name: values.name,
        email: values.email,
        phone: values.phone
    })
}

export const SEGMENT_EVENT = (values, event) => {
    return window.analytics.track(event, {
        name: values.name, 
        email: values.email,
        phone: values.phone
    })
}
