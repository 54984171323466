import React from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

// components
import Container from 'react-bootstrap/Container'
import Layout from '../components/Layout/layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SEO from '../components/SEO/seo'

// utils
import {SLACK_ENDPOINT, SLACK_PAYLOAD, SEGMENT_IDENTIFY, SEGMENT_EVENT} from '../utils/constants'

const formValues = {
    name: '',
    email: '',
    phone: '',
    message: ''
}

const successMessage = 'Message Sent. We\'ll be in touch within 24 hours.'
const errorMessage = 'We had trouble processing your request. Feel free to try again. If this keeps happening, email us at hello@fourwords.co.nz'

const formSchema = Yup.object().shape({
    name: Yup.string()
        .required('Enter your name so we know who you are'),
  
    email: Yup.string()
        .email('Check your email address. Did you add @?')
        .required('Enter your email so we can get in touch with you'),

    message: Yup.string()
        .min(2, 'Enter a message, so we know what you want')
        .required('Let us know what you\'re wanting to contact us about')
})

const requiredAsterix = <span className='text-danger pr-1'>*</span>
  

class ContactPage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            showNotification: false,
            notificationMessage: ''
        }
    }

    sendEmail(values){
        /** SLACK WEBHOOKS: https://stackoverflow.com/questions/45752537/slack-incoming-webhook-request-header-field-content-type-is-not-allowed-by-acce **/
        /** Tldr: Make sure there's no header as it breaks via JS. Alternatives is using header: application/x-www-form-urlencoded **/
        /** Slack payload will break unless it is wrapped within another object **/

        SEGMENT_IDENTIFY(values)
        SEGMENT_EVENT(values, 'contact_form_sent')

        fetch(SLACK_ENDPOINT, {
            method: 'POST',
            body: JSON.stringify(SLACK_PAYLOAD(values))
        }).catch(console.error)
    }

    render(){
        return <>
            <SEO title='Contact' description={'A 100% done-for-you course creation service. We\'ll extract your knowledge and create a course for you in 30 days. Whether you\'re still thinking about if you should create an online course or ready to pull the trigger, book at 30 minute discovery call or email us.'}/>
            <Layout>
                <Container className='bg-white mb-0 py-5' fluid>
                    <Container>
                        <Row className='d-flex pt-5 '>
                            <Col as='section' lg={12}>
                                <h1 className='text-center d-none d-sm-block' >Get in touch</h1>
                                <p className='text-center d-none d-sm-block'>Whether you&apos;re just starting or done all your research, we&apos;re here to help.</p>
                            </Col>
                            <Col lg={12} className='p-0 p-sm-3'>
                                <section className='bg-cream p-3 rounded'>
                                    <h2 className='text-center'>Ask a question</h2>
                                    <p className='text-center'>We normally reply within 24 hours</p>
                                    <Formik initialValues={formValues} validationSchema={formSchema} onSubmit={(values, { setSubmitting, resetForm }) => {
                                        setTimeout(() => {
                                            this.sendEmail(values)
                                            setSubmitting(false)
                                            resetForm()
                                        }, 400)
                                    }}
                                    >
                                        {({ values,errors,touched,handleChange,handleBlur, isSubmitting }) => (
                                            <Form className='col-12'>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>{requiredAsterix}Your name</label>
                                                    <Field className={`form-control ${ errors.name && touched.name ? 'is-invalid' : ''} ${!errors.name && touched.name ? 'is-valid' : ''}`}  type='text' name='name' disabled={isSubmitting}/>
                                                    {errors.name && touched.name && (<small className='form-text text-danger'>{errors.name}</small>)}
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='email'>{requiredAsterix}Email</label>
                                                    <Field className={`form-control ${ errors.email && touched.email ? 'is-invalid' : ''} ${!errors.email && touched.email ? 'is-valid' : ''}`} type='email' name='email' disabled={isSubmitting}/>
                                                    {errors.email && touched.email && (<small className='form-text text-danger'>{errors.email}</small>)}
                                                </div>
                                                <div>
                                                    <label htmlFor='message'>{requiredAsterix}Message</label>
                                                    <Field name='message' as='textarea' spellcheck={true} rows={4} disabled={isSubmitting} className='form-control'onChange={handleChange} onBlur={handleBlur} value={values.message} />
                                                    { errors.message && touched.message && <small className='form-text text-danger'>{errors.message}</small>}
                                                </div>
                                                <hr />
                                                <button className='btn btn-primary mb-3' type='submit' disabled={isSubmitting}>
                                        Send message
                                                </button>
                                            </Form>
                                        )}   

                                    </Formik>
                                    {
                                        this.state.showNotification &&
                                        <div className='shadow-2 py-2 px-3 rounded bg-white'>
                                            <p className='m-0'><small>{this.state.notificationMessage}</small></p> 
                                        </div> 
                                    }
                                </section>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Layout>
        </>
    }
}

export default ContactPage



